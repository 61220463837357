import { isEmpty, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import {
  EXPERIMENT_RCE_VIEWER_ANCHORS,
  EXPERIMENT_ENABLE_POST_READING_TIME_BI,
} from '@wix/communities-blog-experiments';

// import hashLinkHandler from '../../services/hash-link-handler';
import { isInPostPreview } from '../../../common/services/detect-route';
import { getRoute } from '../../../common/router/router-selectors';
import { resolvePostSlug } from '../../../common/services/slug';
import { getIsPostLoaded, getIsRecentPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getPostByIdOrSlug } from '../../../common/selectors/post-selectors';
import { getBasicParams, isSeo, isEditor, isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { simulateComponentError } from '../../../common/services/simulate-error';
import scrollToContent from '../../../common/services/scroll-to-content';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import PostPageLayout from '../../components/post-page-layout';
import Post from '../../../common/components/post';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withAuth from '../../../common/hoc/with-auth';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import withDeviceType from '../../../common/hoc/with-device-type';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withTranslate from '../../../common/hoc/with-translate';
import withExperiment from '../../../common/hoc/with-experiment';
import styles from './post-page.scss';
import ReadingTimeListener from '../../components/reading-time-listener/reading-time-listener';
import { isInWix } from '../../../common/services/is-in-wix';
import OnScreen from '../../../common/components/on-screen';
import RelevantPosts from './relevant-posts';

const PostPageComments = React.lazy(() => import(/* webpackChunkName: "post-page-comments" */ './post-page-comments'));
const PostPageWixComments = React.lazy(() =>
  import(/* webpackChunkName: "post-page-wix-comments" */ './post-page-wix-comments'),
);

export class PostPage extends Component {
  state = { isFetchingPost: false };

  componentDidMount() {
    const { basicParams, post, trackEvent, isRceViewerAnchorsExperimentEnabled } = this.props;
    simulateComponentError(basicParams, 'post-page.container');
    isRceViewerAnchorsExperimentEnabled && trackEvent('ViewContent', { name: post.title });
  }

  getPostContentContainer = () => {
    const post = document.querySelector('[data-hook="post"]');
    return post && post.getBoundingClientRect();
  };

  componentDidUpdate = ({ postSlug: prevPostSlug }) => {
    // hashLinkHandler(this.props.location);
    const { post, postSlug, trackEvent, isRceViewerAnchorsExperimentEnabled } = this.props;

    if (!postSlug || prevPostSlug === postSlug) {
      return;
    }

    scrollToContent(styles.postPage);
    isRceViewerAnchorsExperimentEnabled && trackEvent('ViewContent', { name: post.title });
  };

  reportReadingTime = (props) => {
    this.props.biPostScrolled({
      post_id: this.props.post._id,
      ...props,
    });
  };

  onTabVisibilityChange = (props) => {
    this.props.biActiveTabChanged({
      post_id: this.props.post._id,
      ...props,
    });
  };

  render = () => {
    const {
      post,
      postSlug,
      contentFontClassName,
      isPostLoaded,
      location,
      isSeo,
      isSSR,
      isPostReadingTimeExperimentEnabled,
      showRecentPosts,
      showComments,
      showRelatedPosts,
      relatedPostsLabelKey,
      initWixCommentsController,
      isWixCommentsEnabled,
      isUseWixCommentsEnabled,
      isUseWixCommentsEnabledNew,
    } = this.props;

    const newComments = isWixCommentsEnabled && (isUseWixCommentsEnabled || isUseWixCommentsEnabledNew);
    const canSeePost = post.canSeePaidContent !== false;

    return (
      <PostPageLayout className={classNames(styles.postPage, contentFontClassName)} data-hook="post-page">
        {isPostReadingTimeExperimentEnabled && !isSSR && !isInWix() && (
          <ReadingTimeListener
            onTabVisibilityChange={(props) => this.onTabVisibilityChange(props)}
            onScroll={(props) => this.reportReadingTime(props)}
            getContentContainer={this.getPostContentContainer}
          />
        )}
        <AnimatedLoader isLoading={!isPostLoaded && isEmpty(post)}>
          {this.state.page > 1 ? null : (
            <div className={styles.post}>
              <Post key={post._id} post={post} location={location} isInPostPage />
              <AppLoaded key={post.slug} />
            </div>
          )}
          <RelevantPosts
            post={post}
            showRecentPosts={showRecentPosts}
            showRelatedPosts={showRelatedPosts}
            relatedPostsLabelKey={relatedPostsLabelKey}
          />
          {!isSeo && canSeePost && showComments && (
            <OnScreen>
              {({ isOnScreen, setRef }) => {
                if (!isOnScreen) {
                  return <div ref={setRef} />;
                }
                newComments && initWixCommentsController();
                return (
                  <Suspense fallback={<AnimatedLoader isLoading />}>
                    {newComments && <PostPageWixComments post={post} />}
                    {!newComments && <PostPageComments post={post} postSlug={postSlug} />}
                  </Suspense>
                );
              }}
            </OnScreen>
          )}
        </AnimatedLoader>
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  t: PropTypes.func,
  params: PropTypes.object,
  post: PropTypes.object.isRequired,
  postSlug: PropTypes.string,

  showComments: PropTypes.bool,

  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isEditor: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPostLoaded: PropTypes.bool.isRequired,
  isRecentPostsLoaded: PropTypes.bool.isRequired,
  isSeo: PropTypes.bool.isRequired,

  setIsLoaded: PropTypes.func.isRequired,

  location: PropTypes.object,

  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  biPostScrolled: PropTypes.func.isRequired,
  biActiveTabChanged: PropTypes.func.isRequired,
  initWixCommentsController: PropTypes.func.isRequired,
  isPostReadingTimeExperimentEnabled: PropTypes.bool.isRequired,
  isWixCommentsEnabled: PropTypes.bool.isRequired,
  isUseWixCommentsEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const postSlug = resolvePostSlug(ownProps.params);
  const useDraft = isInPostPreview(getRoute(state));
  let post = getPostByIdOrSlug(state, postSlug) || {};
  post = useDraft ? { ...post, ...(post.draft || {}) } : post;
  const postId = post._id;
  return {
    post,
    postSlug,

    isEditor: isEditor(state),
    isPostLoaded: getIsPostLoaded(state, postSlug),
    isRecentPostsLoaded: getIsRecentPostsLoaded(state, postId),

    isSeo: isSeo(state),
    isSSR: isSSR(state),

    setIsLoaded: actions.setIsLoaded,
    basicParams: getBasicParams(state),
    trackEvent: actions.trackEvent,
    biPostScrolled: actions.biPostScrolled,
    biActiveTabChanged: actions.biActiveTabChanged,
    initWixCommentsController: actions.initWixCommentsController,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFeedMetadataSettings,
  withDeviceType,
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
  withExperiment({
    isRceViewerAnchorsExperimentEnabled: EXPERIMENT_RCE_VIEWER_ANCHORS,
    isPostReadingTimeExperimentEnabled: EXPERIMENT_ENABLE_POST_READING_TIME_BI,
    isWixCommentsEnabled: 'isWixCommentsEnabled',
    isUseWixCommentsEnabled: 'specs.wixBlog.UseWixComments',
    isUseWixCommentsEnabledNew: 'specs.wixBlog.UseWixCommentsNew',
  }),
)(PostPage);
