import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import classNames from 'classnames';
import withHocName from './with-hoc-name';
import withIsFeedDesignEnabled from './with-is-feed-design-enabled';
import withFontClassName from './with-font-class-name';

const withRichContentFontClassName = (WrappedComponent) => {
  const WithRichContentFontClassName = ({ applyFeedDesign, contentFontClassName, getPostClassName, ...props }) => {
    const richContentFontClassName = applyFeedDesign
      ? getPostClassName('description-style-font')
      : contentFontClassName;

    return <WrappedComponent richContentFontClassName={classNames(richContentFontClassName)} {...props} />;
  };

  WithRichContentFontClassName.propTypes = {
    applyFeedDesign: PropTypes.bool.isRequired,
    getPostClassName: PropTypes.func.isRequired,
    contentFontClassName: PropTypes.string.isRequired,
  };

  hoistNonReactStatics(WithRichContentFontClassName, WrappedComponent);

  return flowRight(
    withHocName('WithRichContentFontClassName'),
    withIsFeedDesignEnabled,
    withFontClassName,
  )(WithRichContentFontClassName);
};

export default withRichContentFontClassName;
