import { flowRight, trimStart } from 'lodash';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { isTagLayoutButton, SECTION_BLOG_PAGE, isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';

import { connect } from '../../runtime-context';
import {
  getTagLayout,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
} from '../../../selectors/app-settings-selectors.js';
import { getTagUrl } from '../../../services/get-tag-url';
import { isSite } from '../../../store/basic-params/basic-params-selectors';
import Wix from '../../../services/wix-sdk-polyfill';

import styles from './tag.scss';
import { getSectionUrl } from '../../../store/topology/topology-selectors';
import withTranslate from '../../../hoc/with-translate';
import LinkText from '../../link-text';

const Tag = ({
  tagUrl,
  label,
  tagLayout,
  tagVerticalSpacing,
  tagHorizontalSpacing,
  publicationCount,
  showPostCount,
  tagClicked,
  id,
  postId,
  isSite,
  navigateInPreview,
  isExperimentProdOOIEditorEnabled,
  t,
}) => {
  const tagLayoutButton = isTagLayoutButton(tagLayout);

  const tagStyles = {
    marginLeft: tagHorizontalSpacing,
    marginTop: tagVerticalSpacing,
  };

  const textTagClasses = [styles.blogPostTextTagsFont, styles.blogPostTextTagsColor];
  const buttonTagClasses = [
    styles.tagButton,
    styles.blogPostButtonTagsFont,
    styles.blogPostButtonTagsColor,
    styles.blogPostButtonTagsBorder,
    styles.blogPostButtonTagsBackground,
    styles.blogPostButtonTagsBorderColor,
    styles.blogPostButtonTagsPadding,
  ];

  const tagClassName = classNames(styles.tag, ...(tagLayoutButton ? buttonTagClasses : textTagClasses));

  const clickHandler = (event) => {
    if (!isSite) {
      event.preventDefault();
      if (isExperimentProdOOIEditorEnabled) {
        return Wix.Utils.navigateToSection(
          {
            appDefinitionId: BLOG_APP_ID,
            sectionId: SECTION_BLOG_PAGE,
            shouldRefreshIframe: false,
          },
          trimStart(tagUrl, '/'),
        );
      } else {
        return navigateInPreview(tagUrl);
      }
    }
    tagClicked({ label, id, postId });
  };
  const a11yText =
    publicationCount === 1
      ? t('tag-link.a11y-post-count', { count: publicationCount })
      : t('tag-link.a11y-post-count-plural', { count: publicationCount });
  const postCount = showPostCount ? t('tag-link.post-count', { count: publicationCount }) : null;

  return (
    <li className={styles.tag} style={tagStyles}>
      <a onClick={clickHandler} href={tagUrl} className={tagClassName}>
        <LinkText text={label} postCount={postCount} a11yText={a11yText} />
      </a>
    </li>
  );
};

Tag.propTypes = {
  tagUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  postId: PropTypes.string,
  tagLayout: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
  publicationCount: PropTypes.number,
  showPostCount: PropTypes.bool,
  tagClicked: PropTypes.func.isRequired,
  isSite: PropTypes.bool.isRequired,
  navigateInPreview: PropTypes.func.isRequired,
  isExperimentProdOOIEditorEnabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, { publicationCount, showPostCount, slug }, actions) => {
  const sectionUrl = getSectionUrl(state);

  return {
    tagLayout: getTagLayout(state),
    tagVerticalSpacing: getTagVerticalSpacing(state),
    tagHorizontalSpacing: getTagHorizontalSpacing(state),
    tagUrl: getTagUrl(sectionUrl, slug),
    showPostCount: showPostCount && !!publicationCount,
    tagClicked: actions.tagClicked,
    isSite: isSite(state),
    navigateInPreview: actions.navigateInPreview,
    isExperimentProdOOIEditorEnabled: isExperimentEnabled(state, EXPERIMENT_PROD_OOI_EDITOR),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(Tag);
