import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from '../../../common/components/link/internal-link';
import RecentPostList from '../recent-posts-list';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './recent-posts-desktop.scss';

const RecentPostsDesktop = ({ t, posts, title }) =>
  isEmpty(posts) ? null : (
    <div className={classNames(styles.container, 'blog-text-color')} data-hook="recent-posts">
      {title && (
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>

          <Link to="/" className={styles.link} data-hook="recent-posts-desktop__link">
            {t('recent-posts.all-posts-link')}
          </Link>
        </div>
      )}
      <div className={styles.list}>
        <RecentPostList posts={posts} />
      </div>
    </div>
  );

RecentPostsDesktop.propTypes = {
  t: PropTypes.func,
  posts: PropTypes.array.isRequired,
};

export default withTranslate(RecentPostsDesktop);
