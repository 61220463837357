import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScrollListener from '../../../common/components/scroll-listener';
import { dateLib } from '@wix/communities-blog-client-common';
import { calculateReadingTimeProps } from './reading-time-helpers';

class ReadingTimeListener extends Component {
  state = {
    readingStartTime: dateLib.utc(),
    readingSessionId: `${parseInt(Math.random() * 10000000)}-${dateLib().valueOf()}`,
  };

  componentDidMount() {
    document.addEventListener('visibilitychange', () => this.onFocus());
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', () => this.onFocus());
  }

  onFocus() {
    this.props.onTabVisibilityChange({
      is_on: document.visibilityState === 'visible',
      reading_session_id: this.state.readingSessionId,
    });
  }

  handleScroll = (scroll) => {
    const pageHeight = scroll.target.documentElement.scrollHeight;
    const scrollPosition = scroll.target.documentElement.scrollTop;
    const clientHeight = scroll.target.documentElement.clientHeight;
    const browserWidth = scroll.target.documentElement.clientWidth;
    const scrollableHeight = pageHeight - clientHeight;

    if (scrollableHeight > 0) {
      this.props.onScroll({
        reading_session_id: this.state.readingSessionId,
        ...calculateReadingTimeProps({
          scrollPosition,
          clientHeight,
          scrollableHeight,
          browserWidth,
          readingStartTime: this.state.readingStartTime,
          contentContainer: this.props.getContentContainer(),
        }),
      });
    }
  };

  render() {
    return <ScrollListener onScroll={this.handleScroll} />;
  }
}

ReadingTimeListener.propTypes = {
  getContentContainer: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onTabVisibilityChange: PropTypes.func.isRequired,
};

export default ReadingTimeListener;
